<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->
<template>
  <v-skeleton-loader
    :loading="loading"
    type="text@4,card,text@4"
  >
    <slide-fade-transition>
      <v-container v-if="contractLine || contractError">
        <v-row>
          <v-col
            class="text-center"
            cols="12"
          >
            <h2
              v-if="contractError"
              class="error--text"
            >
              {{ contractError }}
            </h2>
            <h2
              v-else
              class="success--text"
            >
              {{ contractLine }}
            </h2>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col
            cols="12"
            v-html="contract"
          />
          <v-col cols="12">
            <signature-form
              :loading.sync="signatureLoading"
              @signature="updateSignature"
            />
          </v-col>
        </v-row>
      </v-container>
    </slide-fade-transition>
  </v-skeleton-loader>
</template>

<script>
import { MetaInfoMixin } from '@mixins'

import SignatureForm from '@components/SignatureForm'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'ContractSigning',
  components: { SlideFadeTransition, SignatureForm },
  mixins: [MetaInfoMixin],
  props: {
    slug: {
      type: String,
      default: () => undefined
    }
  },
  data () {
    return {
      loading: !1,
      signatureLoading: !1,
      contract: null,
      contractLine: null,
      contractError: null
    }
  },
  mounted () {
    this.fetchSlug()
    // console.log(this.slug)
  },
  methods: {
    fetchSlug () {
      if (this.loading) return
      this.loading = !0
      this.apiService.reservation.publicShow(this.slug, this.$route.query)
        .then(({ _data }) => {
          _data?.html && (this.contract = _data.html)
        })
        .catch(e => {
          const { _message } = e || {}
          this.contractError = _message || e?.message
        })
        .finally(() => {
          this.loading = !1
        })
    },
    updateSignature (signature) {
      if (this.signatureLoading) return
      this.signatureLoading = !0

      this.apiService.reservation.saveSignature(this.slug, signature)
        .then(({ _message, _success }) => {
          if (_success === !0) {
            this.contractLine = _message
          }
        })
        .catch(e => {
          const { _message } = e || {}
          this.alertError(_message || e?.message)
        })
        .finally(() => {
          this.signatureLoading = !1
        })
    }
  }
}
</script>
